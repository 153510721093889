import { SVGAttributes } from 'react';

const ExpandIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 20.5L8 12.5L9.86667 10.6333L16 16.7666L22.1333 10.6333L24 12.5L16 20.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

ExpandIcon.displayName = 'ExpandIcon';

export default ExpandIcon;
