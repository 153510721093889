import { FormEvent, useEffect, useState } from 'react';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';
import { useModalStore } from '@/store';
import { usePutSubscription } from '@/hooks';
import { AmexIcon, MasterCardIcon, VisaIcon } from '@/assets/icons';
import { ButtonLib, Modal } from '@/components';
import { notifications } from '@/utils/notifications';
import styles from './credit-card-details-modal.module.css';

export const CREDIT_CARD_DETAILS_MODAL_NAME = 'CREDIT_CARD_DETAILS_MODAL_NAME';
// Card element is rendered in iFrame, it doesn't support application theme
const CUSTOM_FONT_STYLES = { style: { base: { fontSize: '18px', color: 'white' } } };
const INITIAL_ERROR_STATE = { cardNumber: null, cardExpiry: null, cardCvc: null };

export const CreditCardDetailsModal = () => {
  const navigate = useNavigate();
  const { closeModal, modalOption } = useModalStore();
  const { mutate: updateSubscription, status } = usePutSubscription();
  const [error, setError] = useState(INITIAL_ERROR_STATE);
  const stripe = useStripe();
  const elements = useElements();

  const closeModalAndRedirect = () => {
    navigate(ROUTES.ACCOUNT_SUBSCRIPTION);
    closeModal();
  };

  useEffect(() => {
    if (status === 'success') {
      closeModalAndRedirect();
      setError(INITIAL_ERROR_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleCancel = () => {
    setError(INITIAL_ERROR_STATE);
    closeModalAndRedirect();
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    for (const fieldName in INITIAL_ERROR_STATE) {
      if (error[fieldName as keyof typeof INITIAL_ERROR_STATE] !== null) {
        return;
      }
    }

    try {
      const cardNumber = elements.getElement('cardNumber');
      if (cardNumber) {
        const result = await stripe.createToken(cardNumber);
        updateSubscription({
          plan_id: modalOption.selectedPlanId as string,
          card_token: result.token?.id ?? ''
        });
      }
    } catch (e) {
      notifications.error('Something went wrong. Please try again later');
    }
  };

  const validateField = (event: StripeElementChangeEvent) => {
    setError((prevState) => ({
      ...prevState,
      [event.elementType]: event.error?.message || null
    }));
  };

  return (
    <Modal title="CARD DETAILS" name={CREDIT_CARD_DETAILS_MODAL_NAME} onClose={handleCancel}>
      <div className={styles.label}>Credit or debit card</div>

      <div className="flex items-center gap-2 mb-8">
        <VisaIcon />
        <MasterCardIcon />
        <AmexIcon />
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <label htmlFor="card-number" className={styles.label}>
            Card number
          </label>
          <CardNumberElement
            onChange={validateField}
            id="card-number"
            options={{ ...CUSTOM_FONT_STYLES, showIcon: true }}
            className={styles.input}
          />
          {error.cardNumber ? (
            <p className="text-xs text-hookyred-700 pt-1">{error.cardNumber}</p>
          ) : null}
        </div>

        <div className={styles.twoFields}>
          <div>
            <label htmlFor="card-expiry" className={styles.label}>
              Expiry date
            </label>
            <CardExpiryElement
              onChange={validateField}
              id="card-expiry"
              options={CUSTOM_FONT_STYLES}
              className={styles.input}
            />
            {error.cardExpiry ? (
              <p className="text-xs text-hookyred-700 pt-1">{error.cardExpiry}</p>
            ) : null}
          </div>
          <div>
            <label htmlFor="card-cvc" className={styles.label}>
              Security code
            </label>
            <CardCvcElement
              onChange={validateField}
              id="card-cvc"
              options={CUSTOM_FONT_STYLES}
              className={styles.input}
            />
            {error.cardCvc ? (
              <p className="text-xs text-hookyred-700 pt-1">{error.cardCvc}</p>
            ) : null}
          </div>
        </div>
        <div className={styles.formFooter}>
          <ButtonLib background="secondary" variant="outline" onClick={handleCancel}>
            CANCEL
          </ButtonLib>

          <ButtonLib loading={status === 'loading'} onClick={handleSubmit}>
            SUBMIT
          </ButtonLib>
        </div>
      </form>
    </Modal>
  );
};
