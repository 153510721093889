import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { ISong } from '@/models/Song';
import { DEFAULT_LOADING_LIMIT_ITEM, SORT_ORDERS } from '@/constants';
import { SortOptionsProps } from '@/models/common';

interface UseGetSongsProps extends SortOptionsProps {
  limit?: number;
  isRequireLicense?: boolean;
}

export const useGetSongs = ({
  sortBy = 'status',
  sortOrder = SORT_ORDERS.ASC,
  limit = DEFAULT_LOADING_LIMIT_ITEM,
  isRequireLicense = true
}: UseGetSongsProps): UseInfiniteQueryResult<ISong[], AxiosError> => {
  const api = useApi({});

  return useInfiniteQuery<ISong[], AxiosError, ISong[]>({
    queryKey: ['songs', sortBy, sortOrder, limit, isRequireLicense],
    queryFn: async ({ queryKey, pageParam = 1 }): Promise<ISong[]> => {
      const response = await api.get<ISong[]>(
        `/v1/songs?page=${pageParam}&limit=${limit}&sort_by=${queryKey[1]}&sort_dir=${queryKey[2]}&requires_license=${isRequireLicense}`
      );

      return response.data;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage && lastPage.length === DEFAULT_LOADING_LIMIT_ITEM
        ? allPages.length + 1
        : undefined;
    }
  });
};
