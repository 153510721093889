import { FC } from 'react';
import { ModalFooterBtn, Modal } from '@/components';

import { useModalStore } from '@/store';

interface ModalConfirmSuccessProps {
  onConfirm: () => void;
  name: string;
  title: string;
  subtitle: string;
  width?: number;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onCancel?: () => void;
}

export const ModalConfirmPrimary: FC<ModalConfirmSuccessProps> = ({
  onConfirm,
  title,
  subtitle,
  name,
  width,
  confirmButtonText,
  cancelButtonText,
  onCancel
}) => {
  const { closeModal } = useModalStore();

  const handleClose = () => {
    closeModal();
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };

  const footerBtn: ModalFooterBtn[] = [
    {
      key: 'cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: handleClose,
      content: cancelButtonText || 'CANCEL'
    },
    {
      key: 'confirm',
      onClick: handleConfirm,
      content: confirmButtonText || 'CONFIRM'
    }
  ];

  return (
    <Modal
      size={`${width || 500}px`}
      name={name}
      title={title}
      footerBtns={footerBtn}
      onClose={handleClose}
      className="mb-12"
      isCentered
    >
      <div>{subtitle}</div>
    </Modal>
  );
};
