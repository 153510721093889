import { SVGAttributes } from 'react';

const CreateIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.667 22.666H17.3337V17.3327H22.667V14.666H17.3337V9.33268H14.667V14.666H9.33366V17.3327H14.667V22.666ZM16.0003 29.3327C14.1559 29.3327 12.4225 28.9827 10.8003 28.2827C9.1781 27.5827 7.76699 26.6327 6.56699 25.4327C5.36699 24.2327 4.41699 22.8216 3.71699 21.1993C3.01699 19.5771 2.66699 17.8438 2.66699 15.9993C2.66699 14.1549 3.01699 12.4216 3.71699 10.7993C4.41699 9.17713 5.36699 7.76602 6.56699 6.56602C7.76699 5.36602 9.1781 4.41602 10.8003 3.71602C12.4225 3.01602 14.1559 2.66602 16.0003 2.66602C17.8448 2.66602 19.5781 3.01602 21.2003 3.71602C22.8225 4.41602 24.2337 5.36602 25.4337 6.56602C26.6337 7.76602 27.5837 9.17713 28.2837 10.7993C28.9837 12.4216 29.3337 14.1549 29.3337 15.9993C29.3337 17.8438 28.9837 19.5771 28.2837 21.1993C27.5837 22.8216 26.6337 24.2327 25.4337 25.4327C24.2337 26.6327 22.8225 27.5827 21.2003 28.2827C19.5781 28.9827 17.8448 29.3327 16.0003 29.3327Z"
        fill="currentColor"
      />
    </svg>
  );
};

CreateIcon.displayName = 'CreateIcon';

export default CreateIcon;
