import { SVGAttributes } from 'react';

const CheckIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.7333 24L5.1333 16.4L7.0333 14.5L12.7333 20.2L24.9666 7.96667L26.8666 9.86667L12.7333 24Z"
        fill="currentColor"
      />
    </svg>
  );
};

CheckIcon.displayName = 'CheckIcon';

export default CheckIcon;
