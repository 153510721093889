import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { useModalStore, useSongStore } from '@/store';
import { SelectField, Switch, InputWithInnerPill } from '@/components';
import { useGetGenres, useSongPermission } from '@/hooks';
import { TITLE_VERSIONS } from '@/constants/song';
import { RELEASE_MODAL_NAME } from '@/pages/Projects';

import styles from '../../../song.module.css';

const MOCK_SELECT_DATA = [
  {
    value: 'en',
    label: 'English'
  }
];

export const General = () => {
  const { data: genres = [] } = useGetGenres();
  const { control, getValues, reset, setValue } = useFormContext();
  const { hasReadPermission } = useSongPermission();
  const {
    isSongEditable,
    songData: { status, display_artist, voices }
  } = useSongStore();
  const { modalOption } = useModalStore();

  const isRequire = status === 'release_scheduled' || modalOption.name === RELEASE_MODAL_NAME;

  useEffect(() => {
    setValue('display_artist', display_artist);
  }, [display_artist, setValue]);

  useEffect(() => {
    const values = getValues();

    const formValue = {
      ...values,
      rights_holder: values.rights_holder || '',
      phono_copyright_owner: values.phono_copyright_owner || '',
      copyright_owner: values.copyright_owner || ''
    };

    reset(formValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.form}>
      <SelectField
        label="Song Version"
        name="subtitle"
        placeholder="Original"
        control={control}
        options={TITLE_VERSIONS}
        disabled={hasReadPermission || !isSongEditable}
      />

      <Controller
        render={({ field: { value, onChange } }) => (
          <InputWithInnerPill
            artistModelName={voices?.[0].name}
            value={value}
            onChange={onChange}
            disabled={hasReadPermission || !isSongEditable}
          />
        )}
        disabled={hasReadPermission || !isSongEditable}
        name="display_artist"
        control={control}
      />

      <SelectField
        label="Genre"
        name="genre_id"
        description={isRequire ? '*required' : ''}
        options={genres}
        control={control}
        disabled={hasReadPermission || !isSongEditable}
      />

      <SelectField
        label="Track Language"
        name="audio_language"
        description={isRequire ? '*required' : ''}
        options={MOCK_SELECT_DATA}
        control={control}
        disabled={hasReadPermission || !isSongEditable}
      />

      <Switch
        name="advisory"
        label="Advisory required?"
        leftBox="No"
        rightBox="Explicit"
        hint={isRequire ? '*required' : ''}
        control={control}
        disabled={hasReadPermission || !isSongEditable}
      />
    </div>
  );
};
