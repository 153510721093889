import { SVGAttributes } from 'react';

const ModelsIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.33333 24.0013V8.0013H12V24.0013H9.33333ZM14.6667 29.3346V2.66797H17.3333V29.3346H14.6667ZM4 18.668V13.3346H6.66667V18.668H4ZM20 24.0013V8.0013H22.6667V24.0013H20ZM25.3333 18.668V13.3346H28V18.668H25.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

ModelsIcon.displayName = 'ModelsIcon';

export default ModelsIcon;
