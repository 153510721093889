import { SVGAttributes } from 'react';

const TikTokIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.7511 11.5513C24.7941 13.0275 27.2968 13.8961 29.9998 13.8961V8.63817C29.4883 8.63828 28.978 8.58435 28.4776 8.47717V12.6159C25.7747 12.6159 23.2723 11.7473 21.2288 10.2712V21.0012C21.2288 26.3689 16.9242 30.7199 11.6145 30.7199C9.6333 30.7199 7.79188 30.1145 6.26221 29.0761C8.00807 30.8806 10.4428 32 13.1364 32C18.4465 32 22.7513 27.6489 22.7513 22.281V11.5513H22.7511ZM24.629 6.24668C23.5849 5.09365 22.8994 3.60359 22.7511 1.95625V1.27997H21.3085C21.6716 3.37373 22.9102 5.16251 24.629 6.24668ZM9.6206 24.9571C9.03727 24.1839 8.72204 23.2382 8.72345 22.2657C8.72345 19.8107 10.6924 17.8202 13.1215 17.8202C13.5742 17.8201 14.0241 17.8901 14.4556 18.0285V12.653C13.9514 12.5832 13.4425 12.5535 12.9339 12.5644V16.7484C12.5022 16.6101 12.052 16.5398 11.5992 16.5402C9.17007 16.5402 7.20127 18.5305 7.20127 20.9858C7.20127 22.7219 8.1854 24.2249 9.6206 24.9571Z"
        fill="#FF004F"
      />
      <path
        d="M21.2288 10.2711C23.2723 11.7472 25.7747 12.6158 28.4775 12.6158V8.47706C26.9688 8.1522 25.6332 7.35522 24.629 6.24668C22.91 5.1624 21.6716 3.37362 21.3085 1.27997H17.5192V22.2808C17.5106 24.7291 15.5451 26.7115 13.1212 26.7115C11.6928 26.7115 10.4239 26.0232 9.62024 24.9571C8.18515 24.2249 7.20102 22.7218 7.20102 20.9859C7.20102 18.5308 9.16982 16.5403 11.5989 16.5403C12.0643 16.5403 12.5129 16.6136 12.9337 16.7485V12.5645C7.71725 12.6735 3.52197 16.982 3.52197 22.2809C3.52197 24.9261 4.56669 27.3241 6.26228 29.0762C7.79195 30.1144 9.63338 30.72 11.6146 30.72C16.9244 30.72 21.2289 26.3687 21.2289 21.0012V10.2711H21.2288Z"
        fill="white"
      />
      <path
        d="M28.4777 8.47707V7.35798C27.1172 7.36006 25.7835 6.97492 24.6292 6.24658C25.651 7.37742 26.9965 8.15716 28.4777 8.47707ZM21.3087 1.27998C21.274 1.07988 21.2474 0.878468 21.229 0.676285V0H15.997V21.0011C15.9887 23.449 14.0232 25.4314 11.5991 25.4314C10.8874 25.4314 10.2155 25.2606 9.62044 24.9572C10.4241 26.0233 11.693 26.7114 13.1214 26.7114C15.5451 26.7114 17.5109 24.7292 17.5194 22.281V1.27998H21.3087ZM12.9341 12.5645V11.3732C12.4969 11.3128 12.0562 11.2825 11.6149 11.2827C6.30462 11.2826 2 15.6339 2 21.0011C2 24.366 3.69179 27.3316 6.2626 29.076C4.567 27.324 3.52229 24.9259 3.52229 22.2808C3.52229 16.982 7.71745 12.6735 12.9341 12.5645Z"
        fill="#00F2EA"
      />
    </svg>
  );
};

TikTokIcon.displayName = 'TikTokIcon';

export default TikTokIcon;
