import { SVGAttributes } from 'react';

const ArrowDownIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 8.5L12 15.5L5 8.5"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowDownIcon.displayName = 'ArrowDownIcon';

export default ArrowDownIcon;
