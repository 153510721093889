import {
  ButtonLib,
  InputField,
  Modal,
  Notification,
  SelectField,
  SliderField,
  Tooltip
} from '@/components';
import { Modal as ModalCore } from '@mantine/core';
import {
  ADD_COLLABORATOR_MODAL_NAME,
  ROLE_OPTIONS_DROPDOWN_LIST,
  WORK_TYPE_SONGS
} from '@/constants/song';
import { ParticipantType } from '@/models/Participant.ts';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { setServerFormError } from '@/utils/serverTestValidation.ts';
import { usePostParticipant } from '@/hooks';
import { useModalStore } from '@/store';
import styles from './AddCollaboratorModal.module.css';

interface FormValues {
  name: string;
  role: string;
  email: string;
  split: number;
}

interface AddCollaboratorModalProps {
  isSongWriterForm?: boolean;
  songId: string;
}

const SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
  role: Yup.string().required('Role is required'),
  email: Yup.string().required('Email is required').email('Please enter valid email'),
  split: Yup.number().required()
});

export const AddCollaboratorModal = ({
  isSongWriterForm = false,
  songId
}: AddCollaboratorModalProps) => {
  const { closeModal, modalOption } = useModalStore();
  const userMaxAvailableSplitPercent = modalOption.userMaxAvailableSplitPercent as number;
  const { handleSubmit, control, reset, formState, setError, watch } = useForm<FormValues>({
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      role: isSongWriterForm ? 'lyricist' : '',
      split: userMaxAvailableSplitPercent
    }
  });

  const {
    mutate: addCollaborator,
    isLoading: isAddNewCollaboratorLoading,
    isSuccess: isAddNewCollaboratorSuccess,
    error: addNewCollaboratorError
  } = usePostParticipant({
    workType: WORK_TYPE_SONGS,
    workId: songId
  });

  useEffect(() => {
    if (isAddNewCollaboratorSuccess) {
      closeModal();
      reset({ name: '', role: isSongWriterForm ? 'lyricist' : '' });
    }
  }, [closeModal, isAddNewCollaboratorSuccess, isSongWriterForm, reset]);

  useEffect(() => {
    if (addNewCollaboratorError) {
      setServerFormError(addNewCollaboratorError, setError);
    }
  }, [addNewCollaboratorError, setError]);

  const handleUpdateProfile = handleSubmit((values: FormValues) => {
    const valuesWithType = {
      ...values,
      type: isSongWriterForm ? ParticipantType.PARTICIPANT : ParticipantType.COLLABORATOR
    };
    addCollaborator(valuesWithType);
  });

  const onCancelModal = () => {
    reset({ name: '', role: isSongWriterForm ? 'lyricist' : '' });
    closeModal();
  };

  const isOtherNotificationOpen = watch('role') === 'other';

  return (
    <Modal
      name={ADD_COLLABORATOR_MODAL_NAME}
      onClose={closeModal}
      isCentered
      head={
        <>
          <div className={styles.modalTitleWrapper}>
            <h2 className={styles.modalTitle}>
              ADD {isSongWriterForm ? 'SONGWRITER' : 'COLLABORATOR'}
            </h2>
            <Tooltip tooltipContent="Please ensure the information  that you enter is correct before your new collaborator an invitation for approval" />
          </div>
          <ModalCore.CloseButton className={styles.closeBtn} />
        </>
      }
    >
      {isOtherNotificationOpen ? (
        <Notification variant="info" className={styles.notificationWrapper}>
          'Selecting Other as the collaborator role will NOT publish their name publicly against a
          song'
        </Notification>
      ) : null}
      <form onSubmit={handleUpdateProfile}>
        {!isSongWriterForm ? (
          <SelectField
            name="role"
            label="Role"
            placeholder="Select role*"
            control={control}
            options={ROLE_OPTIONS_DROPDOWN_LIST}
            description="*required"
          />
        ) : null}
        <div className={styles.fieldWrapper}>
          <InputField
            name="name"
            label="Name"
            placeholder="Enter name*"
            control={control}
            description="*required"
          />
        </div>

        <div className={styles.fieldWrapper}>
          <InputField
            name="email"
            label="Email"
            placeholder="Enter email*"
            control={control}
            description="*required"
          />
        </div>

        <div className={styles.fieldWrapper}>
          <SliderField
            valueUnits="%"
            maxValue={userMaxAvailableSplitPercent}
            defaultValue={userMaxAvailableSplitPercent}
            name="split"
            label="Split"
            control={control}
            thumbAlwaysShown={true}
            description="*required"
            maxLength={2}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <ButtonLib
            background="secondary"
            variant="outline"
            onClick={onCancelModal}
            className={styles.button}
          >
            Cancel
          </ButtonLib>

          <ButtonLib
            type="submit"
            loading={isAddNewCollaboratorLoading}
            disabled={!formState.isDirty}
            className={styles.button}
          >
            Add
          </ButtonLib>
        </div>
      </form>
    </Modal>
  );
};
