import { SVGAttributes } from 'react';

const GuideIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 24C16.4667 24 16.8611 23.8389 17.1833 23.5167C17.5056 23.1944 17.6667 22.8 17.6667 22.3333C17.6667 21.8667 17.5056 21.4722 17.1833 21.15C16.8611 20.8278 16.4667 20.6667 16 20.6667C15.5333 20.6667 15.1389 20.8278 14.8167 21.15C14.4944 21.4722 14.3333 21.8667 14.3333 22.3333C14.3333 22.8 14.4944 23.1944 14.8167 23.5167C15.1389 23.8389 15.5333 24 16 24ZM14.8 18.8667H17.2667C17.2667 18.0667 17.3556 17.4778 17.5333 17.1C17.7111 16.7222 18.0889 16.2444 18.6667 15.6667C19.4444 14.8889 19.9944 14.2389 20.3167 13.7167C20.6389 13.1944 20.8 12.6 20.8 11.9333C20.8 10.7556 20.4 9.80556 19.6 9.08333C18.8 8.36111 17.7222 8 16.3667 8C15.1444 8 14.1056 8.3 13.25 8.9C12.3944 9.5 11.8 10.3333 11.4667 11.4L13.6667 12.2667C13.8222 11.6667 14.1333 11.1833 14.6 10.8167C15.0667 10.45 15.6111 10.2667 16.2333 10.2667C16.8333 10.2667 17.3333 10.4278 17.7333 10.75C18.1333 11.0722 18.3333 11.5 18.3333 12.0333C18.3333 12.4111 18.2111 12.8111 17.9667 13.2333C17.7222 13.6556 17.3111 14.1222 16.7333 14.6333C16 15.2778 15.4944 15.8944 15.2167 16.4833C14.9389 17.0722 14.8 17.8667 14.8 18.8667ZM6.66667 28C5.93333 28 5.30556 27.7389 4.78333 27.2167C4.26111 26.6944 4 26.0667 4 25.3333V6.66667C4 5.93333 4.26111 5.30556 4.78333 4.78333C5.30556 4.26111 5.93333 4 6.66667 4H25.3333C26.0667 4 26.6944 4.26111 27.2167 4.78333C27.7389 5.30556 28 5.93333 28 6.66667V25.3333C28 26.0667 27.7389 26.6944 27.2167 27.2167C26.6944 27.7389 26.0667 28 25.3333 28H6.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

GuideIcon.displayName = 'GuideIcon';

export default GuideIcon;
