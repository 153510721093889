import { SVGAttributes } from 'react';

const MasterCardIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="45"
      height="30"
      viewBox="0 0 45 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.895522" y="0.895522" width="43.6716" height="28.209" rx="5.1194" fill="white" />
      <rect
        x="0.895522"
        y="0.895522"
        width="43.6716"
        height="28.209"
        rx="5.1194"
        stroke="#D6DCE5"
        strokeWidth="0.208955"
      />
      <path d="M25.8533 9.25112H19.4541V20.7508H25.8533V9.25112Z" fill="#FF5F00" />
      <path
        d="M19.8604 15.0009C19.8594 13.8934 20.1104 12.8002 20.5943 11.8041C21.0783 10.8079 21.7825 9.93488 22.6537 9.25112C21.5748 8.40309 20.2791 7.87572 18.9147 7.72927C17.5503 7.58282 16.1722 7.82321 14.9379 8.42296C13.7036 9.02272 12.663 9.95764 11.9349 11.1209C11.2069 12.2841 10.8208 13.6287 10.8208 15.0009C10.8208 16.3732 11.2069 17.7178 11.9349 18.881C12.663 20.0442 13.7036 20.9791 14.9379 21.5789C16.1722 22.1787 17.5503 22.419 18.9147 22.2726C20.2791 22.1262 21.5748 21.5988 22.6537 20.7507C21.7825 20.067 21.0783 19.194 20.5943 18.1978C20.1104 17.2016 19.8594 16.1084 19.8604 15.0009Z"
        fill="#EB001B"
      />
      <path
        d="M34.4863 15.0009C34.4863 16.3732 34.1003 17.7178 33.3723 18.881C32.6443 20.0442 31.6037 20.9791 30.3694 21.5789C29.1352 22.1786 27.7571 22.419 26.3927 22.2726C25.0283 22.1262 23.7326 21.5988 22.6537 20.7507C23.5242 20.0663 24.2279 19.1931 24.7117 18.1971C25.1956 17.2011 25.447 16.1083 25.447 15.0009C25.447 13.8936 25.1956 12.8007 24.7117 11.8047C24.2279 10.8087 23.5242 9.93557 22.6537 9.25112C23.7326 8.40309 25.0283 7.87571 26.3927 7.72927C27.7571 7.58282 29.1352 7.82322 30.3694 8.42298C31.6037 9.02274 32.6443 9.95766 33.3723 11.1209C34.1003 12.2841 34.4863 13.6287 34.4863 15.0009Z"
        fill="#F79E1B"
      />
      <path
        d="M33.7885 19.5328V19.2973H33.8835V19.2494H33.6417V19.2973H33.7367V19.5328H33.7885ZM34.2579 19.5328V19.2489H34.1838L34.0986 19.4441L34.0133 19.2489H33.9392V19.5328H33.9915V19.3186L34.0714 19.5033H34.1257L34.2056 19.3182V19.5328H34.2579Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

MasterCardIcon.displayName = 'MasterCardIcon';

export default MasterCardIcon;
