import { MantineProvider } from '@mantine/core';
import { CookiesProvider } from 'react-cookie';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { AppProvider, AuthProvider, ThemeProvider, AudioProvider, mantineTheme } from '@/providers';
import { Routing } from '@/routes';

import 'react-toastify/dist/ReactToastify.css';
import '@mantine/dates/styles.css';
import '@mantine/core/styles.css';
import '@/index.css';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const STRIPE_STYLING_OPTIONS = {
  appearance: {
    variables: {
      borderRadius: '0.375rem',
      colorBackground: mantineTheme.colors.dark[6],
      gridRowSpacing: '1rem',
      fontFamily:
        'Space Grotesk, Arial, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
    },
    rules: {
      '.Input': {
        border: `1px solid ${mantineTheme.colors.dark[2]}`,
        boxShadow: 'none',
        outline: 'none',
        padding: '0.75rem 1rem',
        color: 'white',
        fontSize: '1.125rem',
        borderRadius: '0.25rem'
      },
      '.Input::placeholder': {
        color: '#757575'
      },
      '.Input--invalid': {
        border: `1px solid ${mantineTheme.colors.dark[2]}`,
        color: mantineTheme.colors.red[8],
        boxShadow: 'none',
        outline: 'none'
      },
      '.Input--invalid:focus': {
        boxShadow: 'none',
        border: `1px solid ${mantineTheme.colors.dark[2]}`,
        outline: 'none'
      },
      '.Input:focus': {
        boxShadow: 'none',
        outline: 'none',
        border: `1px solid ${mantineTheme.colors.dark[2]}`
      },
      '.Input:hover': {
        boxShadow: 'none',
        outline: 'none',
        border: '1px solid white'
      },
      '.Label': {
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '1.5',
        color: 'white'
      },
      '.Error': {
        color: mantineTheme.colors.red[8],
        fontSize: '0.75rem',
        lineHeight: '1rem'
      }
    }
  }
};

const App = () => {
  return (
    <MantineProvider theme={mantineTheme}>
      <ThemeProvider>
        <AppProvider>
          <CookiesProvider>
            <AuthProvider>
              <AudioProvider>
                <Elements stripe={stripePromise} options={STRIPE_STYLING_OPTIONS}>
                  <Routing />
                </Elements>
              </AudioProvider>
            </AuthProvider>
          </CookiesProvider>
        </AppProvider>
      </ThemeProvider>
    </MantineProvider>
  );
};

export default App;
