import { useEffect, useState, useRef } from 'react';
import { notifications } from '@/utils/notifications.ts';

export interface SongInfoProps {
  title: string;
  imageUrl: string;
  subtitle: string;
  audio: string;
  id: string;
}
export interface UseAudioProps {
  togglePlay: () => void;
  isPaused: boolean;
  isPlaying: boolean;
  file: string;
  setFile: (data: SongInfoProps) => void;
  toggleRepeat: () => void;
  isRepeating: boolean;
  volume: number;
  setVolume: (volume: number) => void;
  progress: number;
  setProgress: (progress: number) => void;
  duration: number;
  trackInfo?: SongInfoProps;
}

export const useAudio = (): UseAudioProps => {
  const audio = useRef(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);
  const [progress, setProgressInternal] = useState(0);
  const [volume, setVolume] = useState(100);
  const [duration, setDuration] = useState(0);
  const [songInfo, setSongInfo] = useState<SongInfoProps>();

  const setFile = async (data: SongInfoProps) => {
    audio.current.pause();
    audio.current.src = `${data.audio}?noCache=${Math.floor(Date.now() / 1000)}`;
    audio.current.load();
    setProgress(0);
    setSongInfo(data);

    try {
      await audio.current.play();
      setIsPlaying(true);
    } catch (err) {
      notifications.error('Error while playing track');
      setIsPlaying(false);
    }
  };

  const togglePlay = () => {
    setIsPlaying((prevState) => !prevState);
  };

  const toggleRepeat = () => {
    setIsRepeating((prevState) => !prevState);
  };

  const setVolumeValue = (volume: number) => {
    audio.current.volume = volume;
    setVolume(volume);
  };

  const setProgress = (progress: number) => {
    audio.current.currentTime = progress;
    setProgressInternal(progress);
  };

  useEffect(() => {
    const currentAudio = audio.current;
    const updateProgress = () => setProgressInternal(currentAudio.currentTime);
    const setAudioDuration = () => setDuration(currentAudio.duration);

    currentAudio.addEventListener('timeupdate', updateProgress);
    currentAudio.addEventListener('loadedmetadata', setAudioDuration);
    currentAudio.addEventListener('ended', () => setIsPlaying(false));
    currentAudio.loop = isRepeating;

    return () => {
      currentAudio.removeEventListener('timeupdate', updateProgress);
      currentAudio.removeEventListener('loadedmetadata', setAudioDuration);
      currentAudio.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [audio, isRepeating]);

  useEffect(() => {
    const playTrack = async () => {
      try {
        await audio.current.play();
      } catch (e) {
        notifications.error('Error while playing track');
        setIsPlaying(false);
      }
    };

    isPlaying ? playTrack() : audio.current.pause();
  }, [isPlaying, audio]);

  return {
    togglePlay,
    isPaused: !isPlaying,
    isPlaying,
    file: audio.current.src,
    setFile,
    toggleRepeat,
    isRepeating,
    volume,
    setVolume: setVolumeValue,
    progress,
    setProgress,
    duration,
    trackInfo: songInfo
  };
};
