import { Plan } from '@/models/Plan.ts';

export const ENTERPRISE_PLAN: Plan = {
  id: 'enterprise',
  name: 'Enterprise',
  description: 'For commercial use',
  amount: 0,
  interval: 'month',
  highlights: ['We offer customizable plans tailored to your needs.'],
  trial_days: null,
  legal: null,
  link_label: null,
  link_url: null,
  isCustom: true
};
