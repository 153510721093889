import { SVGAttributes } from 'react';

const RoyaltyStarIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33301 12L7.99967 9.96666L10.6663 12L9.66634 8.69999L12.333 6.79999H9.06634L7.99967 3.33333L6.93301 6.79999H3.66634L6.33301 8.69999L5.33301 12ZM7.99967 14.6667C7.07745 14.6667 6.21079 14.4917 5.39967 14.1417C4.58856 13.7917 3.88301 13.3167 3.28301 12.7167C2.68301 12.1167 2.20801 11.4111 1.85801 10.6C1.50801 9.78888 1.33301 8.92222 1.33301 7.99999C1.33301 7.07777 1.50801 6.21111 1.85801 5.39999C2.20801 4.58888 2.68301 3.88333 3.28301 3.28333C3.88301 2.68333 4.58856 2.20833 5.39967 1.85833C6.21079 1.50833 7.07745 1.33333 7.99967 1.33333C8.9219 1.33333 9.78856 1.50833 10.5997 1.85833C11.4108 2.20833 12.1163 2.68333 12.7163 3.28333C13.3163 3.88333 13.7913 4.58888 14.1413 5.39999C14.4913 6.21111 14.6663 7.07777 14.6663 7.99999C14.6663 8.92222 14.4913 9.78888 14.1413 10.6C13.7913 11.4111 13.3163 12.1167 12.7163 12.7167C12.1163 13.3167 11.4108 13.7917 10.5997 14.1417C9.78856 14.4917 8.9219 14.6667 7.99967 14.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

RoyaltyStarIcon.displayName = 'RoyaltyStarIcon';

export default RoyaltyStarIcon;
