import { SVGAttributes } from 'react';

const ApprovalsIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9998 29.3334C14.1554 29.3334 12.4221 28.9834 10.7998 28.2834C9.17761 27.5834 7.7665 26.6334 6.5665 25.4334C5.3665 24.2334 4.4165 22.8222 3.7165 21.2C3.0165 19.5778 2.6665 17.8445 2.6665 16C2.6665 14.1556 3.0165 12.4222 3.7165 10.8C4.4165 9.1778 5.3665 7.76669 6.5665 6.56669C7.7665 5.36669 9.17761 4.41669 10.7998 3.71669C12.4221 3.01669 14.1554 2.66669 15.9998 2.66669C17.4443 2.66669 18.8109 2.8778 20.0998 3.30002C21.3887 3.72224 22.5776 4.31113 23.6665 5.06669L21.7332 7.03335C20.8887 6.50002 19.9887 6.08335 19.0332 5.78335C18.0776 5.48335 17.0665 5.33335 15.9998 5.33335C13.0443 5.33335 10.5276 6.37224 8.44984 8.45002C6.37206 10.5278 5.33317 13.0445 5.33317 16C5.33317 18.9556 6.37206 21.4722 8.44984 23.55C10.5276 25.6278 13.0443 26.6667 15.9998 26.6667C18.9554 26.6667 21.4721 25.6278 23.5498 23.55C25.6276 21.4722 26.6665 18.9556 26.6665 16C26.6665 15.6 26.6443 15.2 26.5998 14.8C26.5554 14.4 26.4887 14.0111 26.3998 13.6334L28.5665 11.4667C28.8109 12.1778 28.9998 12.9111 29.1332 13.6667C29.2665 14.4222 29.3332 15.2 29.3332 16C29.3332 17.8445 28.9832 19.5778 28.2832 21.2C27.5832 22.8222 26.6332 24.2334 25.4332 25.4334C24.2332 26.6334 22.8221 27.5834 21.1998 28.2834C19.5776 28.9834 17.8443 29.3334 15.9998 29.3334ZM14.1332 22.1334L8.4665 16.4667L10.3332 14.6L14.1332 18.4L27.4665 5.03335L29.3332 6.90002L14.1332 22.1334Z"
        fill="currentColor"
      />
    </svg>
  );
};

ApprovalsIcon.displayName = 'ApprovalsIcon';

export default ApprovalsIcon;
