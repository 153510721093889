import { useState, MouseEvent, SyntheticEvent, useRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv, type VariantProps } from 'tailwind-variants';

import defaultSongImage from '@/assets/default-song-image.png';
import { useAudioContext } from '@/providers';
import { IVoice } from '@/models/Voice.ts';
import { Icon, Tooltip, Typo } from '@/components/atoms';
import { useMantineTheme, Tooltip as MantineTooltip } from '@mantine/core';

const artistCardVariants = tv({
  slots: {
    base: 'relative bg-hookybase-50 dark:bg-hookybase-600 rounded-lg shadow-lg p-4 flex gap-2',
    img: 'w-full h-auto',
    imgWrapper: 'relative',
    imgFader:
      'transition-all duration-200 absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center opacity-0 group-hover/card:opacity-100 bg-[#00000099]',
    imgButton:
      'transition-all duration-200 relative cursor-pointer group border border-transparent hover:border-white',
    play: 'absolute bottom-[6%] right-[6%] opacity-0 group-hover/card:opacity-100 z-10 bg-hookyyellow-500 hover:bg-hookyyellow-600 rounded-full flex items-center justify-center shadow-lg w-14 h-14',
    nameTitle:
      'flex-0 block text-hookybase-500 dark:text-white font-bold text-ellipsis overflow-hidden whitespace-nowrap'
  },
  variants: {
    size: {
      sm: {
        nameTitle: 'text-lg',
        play: 'w-10 h-10 bottom-2 right-2'
      },
      md: {
        nameTitle: 'text-xl'
      },
      lg: {
        nameTitle: 'text-2xl',
        play: 'w-16 h-16'
      },
      adapt: {
        nameTitle: 'text-2xl'
      }
    },
    avatarShape: {
      square: {
        img: 'rounded-none',
        imgButton: 'rounded-none'
      },
      circle: {
        img: 'rounded-full',
        imgButton: 'rounded-full'
      }
    },
    layout: {
      horizontal: {
        base: 'flex-row',
        img: 'w-48'
      },
      vertical: {
        base: 'flex-col group/card max-w-[22rem] cursor-pointer dark:hover:bg-hookybase-500  hover:bg-hookybase-100'
      }
    }
  },
  defaultVariants: {
    size: 'adapt',
    avatarShape: 'circle',
    layout: 'vertical'
  }
});

export interface ArtistCardProps extends VariantProps<typeof artistCardVariants> {
  className?: string;
  allowCreate?: boolean;
  artist: Pick<
    IVoice,
    | 'approval_days'
    | 'id'
    | 'avatar_url'
    | 'genre'
    | 'name'
    | 'royalty_percent'
    | 'requires_license'
    | 'audio_preview_url'
  >;
  onCreate?: (id: string) => void;
  onSelect?: (selected: boolean) => void;
}

export const ArtistCard = ({
  allowCreate = true,
  artist: {
    approval_days,
    id,
    avatar_url,
    name,
    royalty_percent,
    // verified,
    genre,
    requires_license,
    audio_preview_url
  },
  className,
  onCreate,
  onSelect,
  ...rest
}: ArtistCardProps) => {
  const { setFile, isPlaying, togglePlay, trackInfo } = useAudioContext();
  const [selected, setSelected] = useState(false);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const [displayedImageUrl, setDisplayedImageUrl] = useState(avatar_url);
  const { colors } = useMantineTheme();
  const titleRef = useRef<HTMLHeadingElement>(null);

  // TODO Temporarily hidden icon
  const verified = false;

  useEffect(() => {
    const scrollWidth = titleRef.current?.scrollWidth || 0;
    const offsetWidth = titleRef.current?.offsetWidth || 0;
    window.addEventListener('resize', () => {
      setIsTitleOverflow(scrollWidth > offsetWidth);
    });
    setIsTitleOverflow(scrollWidth > offsetWidth);
    return () =>
      window.removeEventListener('resize', () => {
        setIsTitleOverflow(scrollWidth > offsetWidth);
      });
  }, []);

  const handleCreateClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onCreate) {
      onCreate(id);
    }
  };

  const handleSelectClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(!selected);
    }

    setSelected(!selected);
  };

  const handlePlayClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (trackInfo?.id === id) {
      togglePlay();
    } else {
      setFile({
        title: `${name}'s preview`,
        imageUrl: avatar_url,
        subtitle: name || '',
        audio: audio_preview_url.String || '',
        id: id || ''
      });
    }
  };

  const { base, img, imgButton, imgWrapper, imgFader, play, nameTitle } = artistCardVariants({
    ...rest,
    className: className || ''
  });

  const isHorizontal = rest.layout === 'horizontal';

  const setStubOnError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.currentTarget.naturalWidth === 0) {
      setDisplayedImageUrl(defaultSongImage);
    }
  };

  return (
    <div id={id} className={base()} onClick={allowCreate ? handleCreateClick : undefined}>
      {selected && (
        <Icon
          size="3xl"
          icon="CheckCircleFill"
          className="absolute top-7 left-7 text-white z-10 shadow"
        />
      )}

      {verified && <Icon icon="Verified" className="absolute z-10" />}

      {requires_license ? (
        <Icon icon="Star" fill={colors.teal[7]} className="top-4 right-4 absolute z-10" />
      ) : null}

      <div className="relative">
        {onSelect ? (
          <button className={imgButton()} onClick={handleSelectClick}>
            <div className={imgWrapper()}>
              <img src={displayedImageUrl} onError={setStubOnError} alt={name} className={img()} />
              <div className={imgFader()}>
                <Typo.h3>create song</Typo.h3>
              </div>
            </div>
          </button>
        ) : (
          <div className={imgWrapper()}>
            <img src={displayedImageUrl} onError={setStubOnError} alt={name} className={img()} />
            <div className={imgFader()}>
              <Typo.h3>create song</Typo.h3>
            </div>
          </div>
        )}

        <button onClick={handlePlayClick} className={play()}>
          <Icon
            size="4xl"
            icon={`mdi:${isPlaying && trackInfo?.id === id ? 'pause' : 'play'}`}
            className="text-black"
          />
        </button>
      </div>

      <div className="mt-2 flex-1 flex flex-col justify-between">
        <div
          className={twMerge(
            !isHorizontal && 'flex justify-between items-center mb-3',
            isHorizontal && 'flex-col'
          )}
        >
          <MantineTooltip label={`${name} AI`} disabled={!isTitleOverflow}>
            <h2 ref={titleRef} className={nameTitle()}>
              {name} AI
            </h2>
          </MantineTooltip>
          {genre ? (
            !isHorizontal ? (
              <div className="rounded w-8 h-8 flex items-center justify-end focus:outline-none group">
                <Tooltip iconSize="lg" tooltipContent={genre.name} />
              </div>
            ) : (
              <p className="text-hookybase-300 dark:text-hookybase-300 text-sm">{genre.name}</p>
            )
          ) : null}
        </div>
        <ul
          className={twMerge(
            isHorizontal && 'border-t border-hookybase-400',
            'text-hookybase-300 dark:text-hookybase-200 text-xs'
          )}
        >
          {royalty_percent && requires_license ? (
            <li
              className={twMerge(
                !isHorizontal && 'justify-start py-1/2',
                isHorizontal && 'justify-between py-2',
                'flex items-center'
              )}
            >
              <div>
                <Icon
                  icon="RoyaltyStar"
                  className="mr-2 text-hookybase-300 dark:text-hookybase-200"
                  size={isHorizontal ? 'xl' : 'md'}
                />
                <span>Royalty Split {isHorizontal ? '' : '•'}</span>
              </div>
              <span
                className={twMerge(
                  !isHorizontal && 'text-hookybase-300 dark:text-hookybase-200',
                  isHorizontal && 'text-hookybase-700 dark:text-hookybase-50',
                  'mr-2'
                )}
              >
                &nbsp;{`${royalty_percent}%${isHorizontal ? ' to artist' : ''}`}
              </span>
            </li>
          ) : (
            <li
              className={twMerge(
                !isHorizontal && 'justify-start py-1/2 h-[17px]',
                isHorizontal && 'hidden'
              )}
            />
          )}

          {approval_days !== undefined && (
            <li
              className={twMerge(
                !isHorizontal && 'justify-start py-1/2',
                isHorizontal && 'justify-between border-y border-hookybase-400 py-2',
                'flex items-center py-1'
              )}
            >
              <div>
                <Icon
                  icon="Approval"
                  className="mr-2 text-hookybase-300 dark:text-hookybase-200"
                  size={isHorizontal ? 'xl' : 'md'}
                />
                <span>{isHorizontal ? 'Commercial license' : 'Approval •'}</span>
              </div>

              <span
                className={twMerge(
                  !isHorizontal && 'text-hookybase-300 dark:text-hookybase-200',
                  isHorizontal && 'text-hookybase-700 dark:text-hookybase-50',
                  'mr-2'
                )}
              >
                &nbsp;{`${approval_days} day(s)`}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
