import { AxiosError } from 'axios';
import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';

import { useApi } from '@/hooks';
import { ISong } from '@/models/Song';
import { DEFAULT_LOADING_LIMIT_ITEM, SORT_ORDERS } from '@/constants';
import { SortOptionsProps } from '@/models/common';

export const useGetCollaborations = ({
  sortBy = 'status',
  sortOrder = SORT_ORDERS.ASC
}: SortOptionsProps): UseInfiniteQueryResult<ISong[], AxiosError> => {
  const api = useApi({});
  const [cookies] = useCookies(['invite_code']);

  return useInfiniteQuery<ISong[], AxiosError, ISong[]>({
    queryKey: ['collaborations', sortBy, sortOrder, cookies.invite_code],
    queryFn: async ({ queryKey, pageParam = 1 }): Promise<ISong[]> => {
      const response = await api.get<ISong[]>(
        `/v1/collaborations?invite_code=${cookies.invite_code}&page=${pageParam}&limit=${DEFAULT_LOADING_LIMIT_ITEM}&sort_by=${queryKey[1]}&sort_dir=${queryKey[2]}`
      );

      return response.data;
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage && lastPage.length === DEFAULT_LOADING_LIMIT_ITEM
        ? allPages.length + 1
        : undefined;
    }
  });
};
