import { SVGAttributes } from 'react';

const PointIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="4" fill="currentColor" />
    </svg>
  );
};

PointIcon.displayName = 'PointIcon';

export default PointIcon;
