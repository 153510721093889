import { SVGAttributes } from 'react';

const TrainModelIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.86667 25.134C5.95556 24.0673 5.25 22.8729 4.75 21.5507C4.25 20.2284 4 18.8229 4 17.334C4 14.0007 5.16667 11.1673 7.5 8.83398C9.83333 6.50065 12.6667 5.33398 16 5.33398H16.2667L14.1333 3.20065L16 1.33398L21.3333 6.66732L16 12.0007L14.1 10.1007L16.2 8.00065H16C13.4222 8.00065 11.2222 8.91176 9.4 10.734C7.57778 12.5562 6.66667 14.7562 6.66667 17.334C6.66667 18.4673 6.85 19.534 7.21667 20.534C7.58333 21.534 8.1 22.434 8.76667 23.234L6.86667 25.134ZM25.1333 25.134L23.2333 23.234C23.9 22.434 24.4167 21.534 24.7833 20.534C25.15 19.534 25.3333 18.4673 25.3333 17.334C25.3333 15.8673 25.0278 14.5062 24.4167 13.2507C23.8056 11.9951 22.9667 10.9451 21.9 10.1007L23.8 8.20065C25.0889 9.31176 26.1111 10.6507 26.8667 12.2173C27.6222 13.784 28 15.4895 28 17.334C28 18.8229 27.75 20.2284 27.25 21.5507C26.75 22.8729 26.0444 24.0673 25.1333 25.134Z"
        fill="currentColor"
      />
      <path
        d="M16 22.5714C15.4 22.5714 14.8864 22.3661 14.4591 21.9554C14.0318 21.5446 13.8182 21.0357 13.8182 20.4286V16.1429C13.8182 15.5357 14.0318 15.0268 14.4591 14.6161C14.8864 14.2054 15.4 14 16 14C16.6182 14 17.1364 14.2054 17.5545 14.6161C17.9727 15.0268 18.1818 15.5357 18.1818 16.1429V20.4286C18.1818 21.0357 17.9727 21.5446 17.5545 21.9554C17.1364 22.3661 16.6182 22.5714 16 22.5714ZM17.0909 29H14.9091V26.2143C13.5091 25.9643 12.3409 25.2991 11.4045 24.2188C10.4682 23.1384 10 21.875 10 20.4286H12.1818C12.1818 21.4643 12.5545 22.3482 13.3 23.0804C14.0455 23.8125 14.9455 24.1786 16 24.1786C17.0727 24.1786 17.9773 23.8125 18.7136 23.0804C19.45 22.3482 19.8182 21.4643 19.8182 20.4286H22C22 21.875 21.5364 23.1384 20.6091 24.2188C19.6818 25.2991 18.5091 25.9643 17.0909 26.2143V29Z"
        fill="currentColor"
      />
    </svg>
  );
};

TrainModelIcon.displayName = 'TrainModelIcon';

export default TrainModelIcon;
