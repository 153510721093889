import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { TextInput } from '@mantine/core';
import { Tooltip } from '@/components';
import styles from './input-with-inner-pill.module.css';

type InputWithInnerPillProps = {
  onChange: (value: string) => void;
  artistModelName?: string;
  value: string;
  disabled?: boolean;
};

const INPUT_CLASSES = {
  root: styles.root,
  wrapper: styles.wrapper,
  input: styles.input
};

export const InputWithInnerPill: FC<InputWithInnerPillProps> = ({
  onChange,
  artistModelName,
  value,
  disabled
}) => {
  const [rightInputValue, setRightInputValue] = useState('');
  const [leftInputValue, setLeftInputValue] = useState('');
  const artistModelNameAI = artistModelName ? `${artistModelName} AI` : undefined;
  const isInitialValueNew = useRef(true);

  useEffect(() => {
    if (value && artistModelNameAI && isInitialValueNew.current) {
      const divided = value.split(artistModelNameAI);
      if (divided.length === 2) {
        setLeftInputValue(divided[0]);
        setRightInputValue(divided[1]);
      }
      isInitialValueNew.current = false;
    }
  }, [artistModelNameAI, value]);

  const onLeftChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLeftInputValue(e.currentTarget.value);
    onChange(`${e.currentTarget.value} ${artistModelNameAI} ${rightInputValue}`);
  };

  const onRightChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRightInputValue(e.currentTarget.value);
    onChange(`${leftInputValue} ${artistModelNameAI} ${e.currentTarget.value}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <div className={styles.labelInner}>
          Display Release Artist name
          <Tooltip tooltipContent="This will be the artist name displayed on the song" />
        </div>
      </div>
      <div className={`${styles.inputsWrapper} ${disabled ? styles.disabled : ''}`}>
        <TextInput
          disabled={disabled}
          value={leftInputValue}
          onChange={onLeftChange}
          classNames={INPUT_CLASSES}
        />
        <span className={styles.pill}>{artistModelNameAI}</span>
        <TextInput
          disabled={disabled}
          value={rightInputValue}
          onChange={onRightChange}
          classNames={INPUT_CLASSES}
        />
      </div>
    </div>
  );
};
