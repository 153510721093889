import { SVGAttributes } from 'react';

const AccountIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0002 16C14.5335 16 13.2779 15.4778 12.2335 14.4334C11.1891 13.3889 10.6668 12.1334 10.6668 10.6667C10.6668 9.20004 11.1891 7.94449 12.2335 6.90004C13.2779 5.8556 14.5335 5.33337 16.0002 5.33337C17.4668 5.33337 18.7224 5.8556 19.7668 6.90004C20.8113 7.94449 21.3335 9.20004 21.3335 10.6667C21.3335 12.1334 20.8113 13.3889 19.7668 14.4334C18.7224 15.4778 17.4668 16 16.0002 16ZM5.3335 26.6667V22.9334C5.3335 22.1778 5.52794 21.4834 5.91683 20.85C6.30572 20.2167 6.82239 19.7334 7.46683 19.4C8.84461 18.7112 10.2446 18.1945 11.6668 17.85C13.0891 17.5056 14.5335 17.3334 16.0002 17.3334C17.4668 17.3334 18.9113 17.5056 20.3335 17.85C21.7557 18.1945 23.1557 18.7112 24.5335 19.4C25.1779 19.7334 25.6946 20.2167 26.0835 20.85C26.4724 21.4834 26.6668 22.1778 26.6668 22.9334V26.6667H5.3335Z"
        fill="currentColor"
      />
    </svg>
  );
};

AccountIcon.displayName = 'AccountIcon';

export default AccountIcon;
