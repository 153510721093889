import { SVGAttributes } from 'react';

const FacebookWhiteIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon-facebook">
        <path
          id="Vector"
          d="M23 11.4941C23 5.14925 17.8481 0 11.5 0C5.15191 0 0 5.14925 0 11.4941C0 17.2352 4.20443 21.9938 9.71163 22.8461V14.8085H6.77446V11.4941H9.71163V8.96089C9.71163 6.08441 11.4171 4.48636 14.0463 4.48636C15.3017 4.48636 16.6045 4.72311 16.6045 4.72311V7.52856H15.1596C13.7266 7.52856 13.2765 8.4282 13.2765 9.32784V11.4822H16.4743L15.965 14.7967H13.2765V22.8343C18.7956 21.9938 23 17.2352 23 11.4941Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M15.977 14.8205L16.4863 11.506H13.2886V9.35161C13.2886 8.44013 13.7505 7.55233 15.1717 7.55233H16.6166V4.73504C16.6166 4.73504 15.3138 4.49829 14.0584 4.49829C11.4291 4.49829 9.72367 6.09634 9.72367 8.97281V11.506H6.7865V14.8205H9.72367V22.858C10.304 22.9527 10.908 23.0001 11.512 23.0001C12.1161 23.0001 12.7201 22.9527 13.3004 22.858V14.8205H15.977Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

FacebookWhiteIcon.displayName = 'FacebookWhiteIcon';

export default FacebookWhiteIcon;
