import { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/core';

import { HELP_CENTER_URL } from '@/config';
import { useGetAccount, useGetSubscription, useGetSongs, useGetCollaborations } from '@/hooks';
import { useModalStore, useNavStore, useSongStore } from '@/store';
import { ROUTES } from '@/routes/routes';
import { Icon, Logo, ModalConfirmPrimary } from '@/components';

type DisplayRoleOptions = 'artist_role' | 'has_one_model' | 'hide_until_ready';
type DisabledRoleOptions = 'is_free_plan';

interface NavItem {
  icon: string;
  label: string;
  url?: string;
  onClick?: () => void;
  new?: boolean;
  isExternal?: boolean;
  displayCondition?: DisplayRoleOptions;
  disabledCondition?: DisabledRoleOptions;
}

interface NavProps {
  className?: string;
  footer?: ReactNode;
  onResize?: () => void;
  onNavigate?: (navItem: NavItem) => void;
}

const FREE_PLAN_TOTAL_CREDITS = 300;
const UPDATE_SUBSCRIPTION_MODAL = 'UPDATE_SUBSCRIPTION_MODAL';

const NAV_ITEMS: NavItem[] = [
  {
    icon: 'Home',
    label: 'Home',
    url: ROUTES.DASHBOARD
  },
  {
    icon: 'Approvals',
    label: 'Approvals',
    url: ROUTES.APPROVALS,
    displayCondition: 'artist_role'
  },
  {
    icon: 'Create',
    label: 'Create',
    url: ROUTES.ARTISTS
  },
  {
    icon: 'Projects',
    label: 'Projects',
    url: ROUTES.PROJECTS
  },
  {
    icon: 'Releases',
    label: 'Releases',
    url: ROUTES.RELEASES,
    displayCondition: 'hide_until_ready'
  },
  {
    icon: 'Models',
    label: 'My Models',
    url: ROUTES.RELEASES,
    displayCondition: 'has_one_model'
  },
  {
    icon: 'Royalities',
    label: 'Royalities',
    url: ROUTES.RELEASES,
    displayCondition: 'hide_until_ready'
  },
  {
    icon: 'TrainModel',
    label: 'Train',
    url: ROUTES.RELEASES,
    disabledCondition: 'is_free_plan'
  },
  {
    icon: 'Help',
    label: 'Help',
    url: HELP_CENTER_URL,
    isExternal: true
  }
];

export const Nav: FC<NavProps> = ({ className = '', footer, onResize, onNavigate }) => {
  const navigate = useNavigate();
  const { isNavOpen, setIsNavOpen } = useNavStore();
  const [displayRoles, setDisplayRoles] = useState<DisplayRoleOptions[]>([]);
  const [disabledRoles, setDisabledRoles] = useState<DisabledRoleOptions[]>([]);
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const { openModal } = useModalStore();
  const { data: user } = useGetAccount();
  const { data: subscription } = useGetSubscription();

  // TODO: Temporary block for testing. Model API to be implemented
  const { sortOptions } = useSongStore();
  const { data: songs = { pages: [] } } = useGetSongs(sortOptions);
  const { data: collaborations = { pages: [] } } = useGetCollaborations(sortOptions);

  useEffect(() => {
    // TODO: Songs and collaborations to be replaced by models
    if (songs.pages[0]?.length > 0 || collaborations.pages[0]?.length > 0) {
      setDisplayRoles((prevState) => prevState.concat('has_one_model'));
    }
  }, [collaborations, songs]);

  useEffect(() => {
    if (user && user.artist) {
      setDisplayRoles((prevState) => prevState.concat('artist_role'));
    }
  }, [user]);

  useEffect(() => {
    if (subscription && subscription.total_credits === FREE_PLAN_TOTAL_CREDITS) {
      setDisabledRoles((prevState) => prevState.concat('is_free_plan'));
    }
  }, [subscription]);

  useEffect(() => {
    if (onResize) onResize();
  }, [onResize, isNavOpen]);

  const handleMouseEnter = () => {
    setIsNavOpen(true);
  };

  const closeNavPanel = () => {
    setIsNavOpen(false);
  };

  const getIsDisabled = (disabledCondition?: DisabledRoleOptions) =>
    disabledCondition && disabledRoles.includes(disabledCondition);

  const handleOpenUpdateSubscriptionModal = () => {
    openModal({ name: UPDATE_SUBSCRIPTION_MODAL });
  };

  const handleNavigation = (navItem: NavItem) => (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (getIsDisabled(navItem.disabledCondition)) {
      handleOpenUpdateSubscriptionModal();
      return;
    }
    if (navItem.isExternal) return;
    if (navItem?.onClick) return navItem.onClick();
    if (navItem?.url) return navigate(navItem.url);
    if (onNavigate) return onNavigate(navItem);
    return;
  };

  const drawerClass = twMerge(
    'absolute md:fixed top-[3.875rem] md:top-0 left-0 bottom-0 z-50 text-hookybase-100 dark:text-white bg-white dark:bg-black transition-all duration-75',
    'border-0 md:border-r-2 md:border-hookybase-50 md:dark:border-hookybase-500',
    isNavOpen && 'p-3 w-full md:w-[180px]',
    !isNavOpen && 'p-0 md:p-3 w-0 md:w-[80px]',
    className
  );

  const itemClass = twMerge(
    'flex items-center gap-4 w-full rounded py-2.5 text-hookybase-300 [@media(hover:hover){&:hover}]:bg-hookybase-50 dark:[@media(hover:hover){&:hover}]:bg-hookybase-500 dark:[@media(hover:hover){&:hover}]:text-hookyyellow-500',
    isNavOpen && 'pl-4',
    !isNavOpen && 'justify-center'
  );

  const navigateToSubscriptions = () => {
    setIsNavOpen(false);
    navigate(ROUTES.ACCOUNT_SUBSCRIPTION);
  };

  return (
    <div
      className={drawerClass}
      onTouchEnd={!md ? closeNavPanel : undefined}
      onMouseEnter={md ? handleMouseEnter : undefined}
      onMouseLeave={closeNavPanel}
    >
      <Logo className="h-12 mt-3 mb-6 text-hookybase-500" expand={isNavOpen} />
      <ul className={`flex flex-col ${isNavOpen ? 'open' : 'closed items-center'}`}>
        {NAV_ITEMS.map((item, index) =>
          !item.displayCondition ||
          (item.displayCondition && displayRoles.includes(item.displayCondition)) ||
          user?.artist ? (
            <li
              key={index}
              onClick={handleNavigation(item)}
              className={twMerge('w-full mb-1 cursor-pointer select-none')}
            >
              {item.isExternal ? (
                <a href={item.url} className={itemClass} target="_blank" rel="noopener noreferrer">
                  <Icon icon={item.icon} size="3xl" />
                  {isNavOpen && <span className="uppercase text-sm">{item.label}</span>}
                </a>
              ) : (
                <div
                  className={twMerge(
                    itemClass,
                    getIsDisabled(item.disabledCondition) &&
                      'opacity-50 dark:[@media(hover:hover){&:hover}]:text-hookybase-350'
                  )}
                >
                  <Icon icon={item.icon} size="3xl" />
                  {isNavOpen && <span className="uppercase text-sm">{item.label}</span>}
                </div>
              )}
            </li>
          ) : null
        )}
      </ul>
      {footer && <div className="footer">{footer}</div>}
      <ModalConfirmPrimary
        title="UPGRADE YOUR PLAN"
        subtitle="Your current plan does not allow for you to train voice models, upgrade your plan now to
        access our premium features."
        name={UPDATE_SUBSCRIPTION_MODAL}
        confirmButtonText="UPGRADE"
        cancelButtonText="CLOSE"
        onConfirm={navigateToSubscriptions}
        onCancel={closeNavPanel}
      />
    </div>
  );
};
