import { SVGAttributes } from 'react';

const PlayCircleIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="36"
      height="38"
      viewBox="0 0 36 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_79_515)" filter="url(#filter0_d_79_515)">
        <rect x="9.42871" y="8.57141" width="16" height="16" fill="#1C1C1C" />
        <g filter="url(#filter1_d_79_515)">
          <path
            d="M14.6665 22L23.9998 16L14.6665 9.99996V22ZM17.9998 29.3333C16.1554 29.3333 14.4221 28.9833 12.7998 28.2833C11.1776 27.5833 9.7665 26.6333 8.5665 25.4333C7.3665 24.2333 6.4165 22.8222 5.7165 21.2C5.0165 19.5777 4.6665 17.8444 4.6665 16C4.6665 14.1555 5.0165 12.4222 5.7165 10.8C6.4165 9.17774 7.3665 7.76663 8.5665 6.56663C9.7665 5.36663 11.1776 4.41663 12.7998 3.71663C14.4221 3.01663 16.1554 2.66663 17.9998 2.66663C19.8443 2.66663 21.5776 3.01663 23.1998 3.71663C24.8221 4.41663 26.2332 5.36663 27.4332 6.56663C28.6332 7.76663 29.5832 9.17774 30.2832 10.8C30.9832 12.4222 31.3332 14.1555 31.3332 16C31.3332 17.8444 30.9832 19.5777 30.2832 21.2C29.5832 22.8222 28.6332 24.2333 27.4332 25.4333C26.2332 26.6333 24.8221 27.5833 23.1998 28.2833C21.5776 28.9833 19.8443 29.3333 17.9998 29.3333Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_79_515"
          x="-2"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_79_515" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_79_515"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_79_515"
          x="0.666504"
          y="2.66663"
          width="34.6665"
          height="34.6666"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_79_515" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_79_515"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_79_515">
          <rect width="32" height="32" fill="white" transform="translate(2)" />
        </clipPath>
      </defs>
    </svg>
  );
};

PlayCircleIcon.displayName = 'PlayCircleIcon';

export default PlayCircleIcon;
