import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';
import { useModalStore } from '@/store';
import { useGetPlans, useGetSubscription } from '@/hooks';
import { PlanNames } from '@/models/Plan';
import AccountLayout from '@/layouts/AccountLayout';
import { ModalConfirmDanger, OneButtonProps, Tab, Tabs } from '@/components';
import {
  getPrevCardLabel,
  fremiumPlans,
  enterprisePlans,
  plusProPlans,
  SubscriptionPlanCard
} from '@/components/molecules/SubscriptionPlanCard';
import { ENTERPRISE_PLAN } from './constants.ts';
import { SUCCESS_SUBSCRIPTION_CHANGE_MODAL } from './ConfirmSubscriptionModal';
import styles from './subscription.module.css';

const BUTTON_CONFIRM_PROPS: OneButtonProps = {
  text: 'Close',
  variant: 'filled',
  background: 'primary',
  size: 'lg'
};

const AccountSubscription = () => {
  const { closeModal } = useModalStore();
  const navigate = useNavigate();
  const { data: basePlans = [] } = useGetPlans();
  const plans = [...basePlans, ENTERPRISE_PLAN];
  const { data: currentSubscription } = useGetSubscription();

  const closeModalAndRedirect = () => {
    navigate(ROUTES.ACCOUNT_SUBSCRIPTION);
    closeModal();
  };

  const filterBy = (filters: Record<string, string | PlanNames[]>) =>
    plans?.filter((plan) =>
      Object.keys(filters).every((key) => {
        if (!filters[key].length) {
          return true;
        }
        return (filters as unknown as Record<string, string | string[]>)[key].includes(
          (plan as unknown as Record<string, string>)[key]
        );
      })
    );

  const currentPlanId = currentSubscription?.next_plan_id || currentSubscription?.plan_id;

  return (
    <AccountLayout title="Available plans" subtitle="See our available plans below">
      <Tabs className={styles.planTabs}>
        <Tab label="All" data-testid="allSubscriptions" className={styles.planTab}>
          {plans?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentPlanId}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>

        <Tab label="Freemium" data-testid="freemiumSubscriptions" className={styles.planTab}>
          {filterBy({ name: fremiumPlans })?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentPlanId}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>

        <Tab label="Plus & Pro" data-testid="plusProSubscriptions" className={styles.planTab}>
          {filterBy({ name: plusProPlans })?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentPlanId}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>

        <Tab label="Enterprise" data-testid="enterpriseSubscriptions" className={styles.planTab}>
          {filterBy({ name: enterprisePlans })?.map((card, index) => (
            <SubscriptionPlanCard
              key={index}
              isHorizontal={true}
              card={card}
              currentPlanId={currentPlanId}
              prevCardLabel={getPrevCardLabel(plans, index)}
            />
          ))}
        </Tab>
      </Tabs>

      <Outlet />

      <ModalConfirmDanger
        name={SUCCESS_SUBSCRIPTION_CHANGE_MODAL}
        subtitle="You have successfully upgraded/downgraded your subscription plan."
        title="Success!"
        onConfirm={closeModalAndRedirect}
        onClose={closeModalAndRedirect}
        oneButton={BUTTON_CONFIRM_PROPS}
      />
    </AccountLayout>
  );
};
export default AccountSubscription;
