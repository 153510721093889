import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { ROUTES } from '@/routes/routes.tsx';
import { useModalStore } from '@/store';
import { useSelectEnterprisePlan } from '@/hooks';
import { InputField, ModalFooterBtn, Modal, NumberInputField } from '@/components';

import styles from './enterprise-modal.module.css';

export const ENTERPRISE_REQUEST_MODAL = 'enterprise_request_modal';

const SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone_number: Yup.string().required('Number is required'),
  email: Yup.string().email('Invalid email').required('Email is required')
});

interface FormValues {
  name: string;
  phone_number: string;
  email: string;
}

interface FormError {
  response: {
    data: {
      phone_number: string;
      email: string;
      name: string;
    };
  };
}

export const EnterpriseModal = () => {
  const navigate = useNavigate();
  const { closeModal } = useModalStore();
  const { mutate: updateSubscription, status, error } = useSelectEnterprisePlan();

  const { handleSubmit, control, setError } = useForm<FormValues>({
    resolver: yupResolver(SCHEMA)
  });

  const closeModalAndRedirect = () => {
    navigate(ROUTES.ACCOUNT_SUBSCRIPTION);
    closeModal();
  };

  useEffect(() => {
    if (status === 'success') {
      closeModalAndRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (error) {
      const phoneNumber = (error as FormError).response.data.phone_number;

      if (phoneNumber) {
        setError('phone_number', {
          type: 'custom',
          message: phoneNumber.replace('phone_number', 'Phone number')
        });
      }
    }
  }, [error, setError]);

  const handleConfirm = handleSubmit((values) => {
    updateSubscription(values);
  });

  const footerBtns: ModalFooterBtn[] = [
    {
      key: 'cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: closeModalAndRedirect,
      content: 'CANCEL'
    },
    {
      key: 'confirm',
      disabled: status === 'loading',
      loading: status === 'loading',
      onClick: handleConfirm,
      content: 'SUBMIT'
    }
  ];

  return (
    <Modal
      name={ENTERPRISE_REQUEST_MODAL}
      title="ENTERPRISE PLAN REQUEST"
      footerBtns={footerBtns}
      onClose={closeModalAndRedirect}
      isCentered
    >
      <div className={styles.fieldWrapper}>
        <InputField
          name="name"
          label="Name"
          placeholder="Enter name*"
          control={control}
          description="*required"
        />
      </div>

      <div className={styles.fieldWrapper}>
        <NumberInputField
          name="phone_number"
          label="Number"
          placeholder="Enter number*"
          description="*required"
          maxLength={15}
          control={control}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <InputField
          name="email"
          label="Email"
          placeholder="Enter email*"
          control={control}
          description="*required"
        />
      </div>
    </Modal>
  );
};
