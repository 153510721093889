import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useQueryClient } from '@tanstack/react-query';

import { notifications } from '@/utils/notifications';
import { ButtonLib } from '@/components';
import { usePutSubscription } from '@/hooks';
import { VisaIcon, MasterCardIcon, AmexIcon } from '@/assets/icons';

import styles from '../../../account.module.css';
import { PAYMENT_CONTENT_MODE } from '../../constants';

const INITIAL_ERROR_STATE = { cardNumber: null, cardExpiry: null, cardCvc: null };
const CUSTOM_FONT_STYLES = {
  style: { base: { fontSize: '18px', color: 'white' } }
};

interface FormValues {
  cardNumber: string;
  expiryDate: boolean;
  cvc?: string;
}

interface StripePaymentCardProps {
  onChangeMode: (mode: string, isNeedRefresh?: boolean) => void;
}

export const StripePaymentCard = (props: StripePaymentCardProps) => {
  const { onChangeMode } = props;
  const { state } = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const { mutate: updateSubscription, status } = usePutSubscription();
  const [error, setError] = useState(INITIAL_ERROR_STATE);
  const [isDirty, setIsDirty] = useState({});
  const queryClient = useQueryClient();
  const hasDirtyAllFields = Object.keys(isDirty).length === 3;

  useEffect(() => {
    if (status === 'success') {
      if (state?.plan_id) {
        notifications.success(
          state.plan_id
            ? 'Payment details has been updated successfully'
            : 'Credit/Debit Card has been successfully saved'
        );
      } else setError(INITIAL_ERROR_STATE);
    }
  }, [state?.plan_id, status]);

  const { handleSubmit } = useForm<FormValues>();

  const validateField = (event: StripeElementChangeEvent) => {
    setIsDirty((prevState) => ({
      ...prevState,
      [event.elementType]: true
    }));

    setError((prevState) => ({
      ...prevState,
      [event.elementType]: event.error?.message || null
    }));
  };

  const handleSave = handleSubmit(async () => {
    if (!stripe || !elements) {
      return;
    }

    const addressElement = elements.getElement('address');
    const addressValues = await addressElement?.getValue();

    if (!addressValues || !addressValues.complete) {
      return;
    }

    for (const fieldName in INITIAL_ERROR_STATE) {
      if (error[fieldName as keyof typeof INITIAL_ERROR_STATE] !== null) {
        return;
      }
    }

    try {
      const cardNumber = elements.getElement('cardNumber');

      if (cardNumber && hasDirtyAllFields && addressValues.complete) {
        const {
          address: { line1, line2, city, country, postal_code, state },
          name
        } = addressValues.value;
        const result = await stripe.createToken(cardNumber, {
          name,
          address_line1: line1,
          address_line2: line2 || '',
          address_city: city,
          address_state: state,
          address_zip: postal_code,
          address_country: country
        });

        if (result.error) {
          notifications.error(result.error.message);

          return;
        }

        if (result.token?.id) {
          void queryClient.removeQueries(['account-card']);

          updateSubscription({
            card_token: result.token?.id
          });

          onChangeMode(PAYMENT_CONTENT_MODE.VIEW, true);
        }
      }
    } catch (e) {
      notifications.error('Something went wrong. Please try again later');
    }
  });

  return (
    <>
      <div className={styles.cardList}>
        <div className={styles.titleSmall}>Credit or debit card</div>

        <div className="flex items-center gap-2 mb-8">
          <VisaIcon />
          <MasterCardIcon />
          <AmexIcon />
        </div>
      </div>

      <form className={styles.form}>
        <div>
          <label htmlFor="card-number" className={styles.label}>
            Card number
          </label>

          <CardNumberElement
            onChange={validateField}
            id="card-number"
            options={{ ...CUSTOM_FONT_STYLES, showIcon: true }}
            className={styles.input}
          />

          {error.cardNumber ? (
            <p className="text-xs text-hookyred-700 pt-1">{error.cardNumber}</p>
          ) : null}
        </div>

        <div className={styles.twoFields}>
          <div>
            <label htmlFor="card-expiry" className={styles.label}>
              Expiry date
            </label>

            <CardExpiryElement
              onChange={validateField}
              id="card-expiry"
              options={CUSTOM_FONT_STYLES}
              className={styles.input}
            />

            {error.cardExpiry ? (
              <p className="text-xs text-hookyred-700 pt-1">{error.cardExpiry}</p>
            ) : null}
          </div>

          <div>
            <label htmlFor="card-cvc" className={styles.label}>
              Security code
            </label>

            <CardCvcElement
              onChange={validateField}
              id="card-cvc"
              options={CUSTOM_FONT_STYLES}
              className={styles.input}
            />

            {error.cardCvc ? (
              <p className="text-xs text-hookyred-700 pt-1">{error.cardCvc}</p>
            ) : null}
          </div>
        </div>

        <AddressElement
          className="mt-6"
          options={{
            mode: 'billing',
            autocomplete: {
              mode: 'automatic'
            }
          }}
        />
      </form>

      <div className={`${styles.formActions} ${styles.formFooter}`}>
        <ButtonLib
          loading={status === 'loading'}
          onClick={handleSave}
          disabled={!hasDirtyAllFields}
        >
          {state?.plan_id ? 'SUBMIT' : 'SAVE'}
        </ButtonLib>
      </div>
    </>
  );
};
