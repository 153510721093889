import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCardDetail, useGetPlans, useGetSubscription } from '@/hooks';
import { SubscriptionModalOptions, useModalStore } from '@/store';
import { CREDIT_CARD_DETAILS_MODAL_NAME, CreditCardDetailsModal } from '../CreditCardDetailsModal';
import {
  CONFIRM_SUBSCRIPTION_CHANGE_MODAL,
  ConfirmSubscriptionModal
} from '../ConfirmSubscriptionModal';
import { ENTERPRISE_REQUEST_MODAL, EnterpriseModal } from '../EnterpriseModal';
import { ENTERPRISE_PLAN } from '../constants.ts';

const SelectedPlanModals = () => {
  const { planName } = useParams();
  const { openModal } = useModalStore();
  const { data: cardDetails, isFetching: isCardDetailsFetching } = useGetCardDetail();
  const { data: currentSubscription } = useGetSubscription();
  const { data: basePlans = [], isSuccess: isPlansFetchedSuccessfully } = useGetPlans();

  useEffect(() => {
    if (planName && basePlans) {
      if (planName === 'Enterprise') {
        openModal({
          name: ENTERPRISE_REQUEST_MODAL
        });

        return;
      }

      if (isCardDetailsFetching) {
        return;
      }

      const selectedId = basePlans.find((plan) => plan.name === planName)?.id;

      if (cardDetails) {
        const plans = [...basePlans, ENTERPRISE_PLAN];
        const data = plans?.reduce(
          (acc, plan) => {
            if (plan.id === selectedId) {
              acc.selected = {
                id: selectedId,
                name: plan.name,
                amount: plan.amount,
                interval: plan.interval
              };
            } else if (plan.id === currentSubscription?.plan_id) {
              acc.current = {
                name: plan.name,
                amount: plan.amount,
                interval: plan.interval
              };
            }
            return acc;
          },
          { current: {}, selected: {} } as SubscriptionModalOptions
        );

        openModal({
          name: CONFIRM_SUBSCRIPTION_CHANGE_MODAL,
          data
        });
      } else {
        openModal({ name: CREDIT_CARD_DETAILS_MODAL_NAME, selectedPlanId: selectedId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlansFetchedSuccessfully, isCardDetailsFetching, planName]);

  return (
    <>
      <CreditCardDetailsModal />
      <ConfirmSubscriptionModal />
      <EnterpriseModal />
    </>
  );
};

export default SelectedPlanModals;
