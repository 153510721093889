import { SVGAttributes } from 'react';

const VisibilityOffIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.4002 30.1333L20.8002 24.5999C20.0224 24.8444 19.2391 25.0277 18.4502 25.1499C17.6613 25.2722 16.8446 25.3333 16.0002 25.3333C12.6446 25.3333 9.65572 24.4055 7.0335 22.5499C4.41127 20.6944 2.51127 18.2888 1.3335 15.3333C1.80016 14.1555 2.38905 13.0611 3.10016 12.0499C3.81127 11.0388 4.62239 10.1333 5.5335 9.33328L1.86683 5.59994L3.7335 3.73328L28.2668 28.2666L26.4002 30.1333ZM16.0002 21.3333C16.2446 21.3333 16.4724 21.3222 16.6835 21.2999C16.8946 21.2777 17.1224 21.2333 17.3668 21.1666L10.1668 13.9666C10.1002 14.2111 10.0557 14.4388 10.0335 14.6499C10.0113 14.8611 10.0002 15.0888 10.0002 15.3333C10.0002 16.9999 10.5835 18.4166 11.7502 19.5833C12.9168 20.7499 14.3335 21.3333 16.0002 21.3333ZM25.7335 21.9333L21.5002 17.7333C21.6557 17.3555 21.7779 16.9722 21.8668 16.5833C21.9557 16.1944 22.0002 15.7777 22.0002 15.3333C22.0002 13.6666 21.4168 12.2499 20.2502 11.0833C19.0835 9.91661 17.6668 9.33328 16.0002 9.33328C15.5557 9.33328 15.1391 9.37772 14.7502 9.46661C14.3613 9.5555 13.9779 9.68883 13.6002 9.86661L10.2002 6.46661C11.1113 6.08883 12.0446 5.8055 13.0002 5.61661C13.9557 5.42772 14.9557 5.33328 16.0002 5.33328C19.3557 5.33328 22.3446 6.26105 24.9668 8.11661C27.5891 9.97217 29.4891 12.3777 30.6668 15.3333C30.1557 16.6444 29.4835 17.8611 28.6502 18.9833C27.8168 20.1055 26.8446 21.0888 25.7335 21.9333ZM19.5668 15.7999L15.5668 11.7999C16.1891 11.6888 16.7613 11.7388 17.2835 11.9499C17.8057 12.1611 18.2557 12.4666 18.6335 12.8666C19.0113 13.2666 19.2835 13.7277 19.4502 14.2499C19.6168 14.7722 19.6557 15.2888 19.5668 15.7999Z"
        fill="currentColor"
      />
    </svg>
  );
};

VisibilityOffIcon.displayName = 'VisibilityOffIcon';

export default VisibilityOffIcon;
