import { SVGAttributes } from 'react';

const ThumbUpIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 42H16V16L30 2L32.5 4.5C32.7333 4.73333 32.925 5.05 33.075 5.45C33.225 5.85 33.3 6.23333 33.3 6.6V7.3L31.1 16H42C43.0667 16 44 16.4 44.8 17.2C45.6 18 46 18.9333 46 20V24C46 24.2333 45.975 24.4833 45.925 24.75C45.875 25.0167 45.8 25.2667 45.7 25.5L39.7 39.6C39.4 40.2667 38.9 40.8333 38.2 41.3C37.5 41.7667 36.7667 42 36 42ZM12 16V42H4V16H12Z"
        fill="#545454"
      />
    </svg>
  );
};

ThumbUpIcon.displayName = 'ThumbUpIcon';

export default ThumbUpIcon;
