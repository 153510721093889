import { SVGAttributes } from 'react';

const AppleIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.8182 10.9088C28.6282 11.0528 25.2735 12.8992 25.2735 17.0048C25.2735 21.7536 29.5422 23.4336 29.6699 23.4752C29.6503 23.5776 28.9918 25.776 27.4193 28.016C26.0172 29.9872 24.5528 31.9552 22.3251 31.9552C20.0975 31.9552 19.5242 30.6912 16.9525 30.6912C14.4464 30.6912 13.5553 31.9968 11.5176 31.9968C9.47993 31.9968 8.05815 30.1728 6.42342 27.9328C4.52989 25.3024 3 21.216 3 17.3376C3 11.1168 7.14087 7.8176 11.2162 7.8176C13.3817 7.8176 15.1867 9.2064 16.5463 9.2064C17.8403 9.2064 19.8583 7.7344 22.3219 7.7344C23.2555 7.7344 26.6102 7.8176 28.8182 10.9088ZM21.1523 5.1008C22.1712 3.92 22.8919 2.2816 22.8919 0.6432C22.8919 0.416 22.8722 0.1856 22.8296 0C21.172 0.0608 19.1998 1.0784 18.0106 2.4256C17.077 3.4624 16.2056 5.1008 16.2056 6.7616C16.2056 7.0112 16.2482 7.2608 16.2678 7.3408C16.3726 7.36 16.543 7.3824 16.7133 7.3824C18.2007 7.3824 20.0713 6.4096 21.1523 5.1008Z"
        fill="currentColor"
      />
    </svg>
  );
};

AppleIcon.displayName = 'AppleIcon';

export default AppleIcon;
