import { SVGAttributes } from 'react';

const ListIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.33333 12V9.33337H28V12H9.33333ZM9.33333 17.3334V14.6667H28V17.3334H9.33333ZM9.33333 22.6667V20H28V22.6667H9.33333ZM5.33333 12C4.95556 12 4.63889 11.8723 4.38333 11.6167C4.12778 11.3612 4 11.0445 4 10.6667C4 10.2889 4.12778 9.97226 4.38333 9.71671C4.63889 9.46115 4.95556 9.33337 5.33333 9.33337C5.71111 9.33337 6.02778 9.46115 6.28333 9.71671C6.53889 9.97226 6.66667 10.2889 6.66667 10.6667C6.66667 11.0445 6.53889 11.3612 6.28333 11.6167C6.02778 11.8723 5.71111 12 5.33333 12ZM5.33333 17.3334C4.95556 17.3334 4.63889 17.2056 4.38333 16.95C4.12778 16.6945 4 16.3778 4 16C4 15.6223 4.12778 15.3056 4.38333 15.05C4.63889 14.7945 4.95556 14.6667 5.33333 14.6667C5.71111 14.6667 6.02778 14.7945 6.28333 15.05C6.53889 15.3056 6.66667 15.6223 6.66667 16C6.66667 16.3778 6.53889 16.6945 6.28333 16.95C6.02778 17.2056 5.71111 17.3334 5.33333 17.3334ZM5.33333 22.6667C4.95556 22.6667 4.63889 22.5389 4.38333 22.2834C4.12778 22.0278 4 21.7112 4 21.3334C4 20.9556 4.12778 20.6389 4.38333 20.3834C4.63889 20.1278 4.95556 20 5.33333 20C5.71111 20 6.02778 20.1278 6.28333 20.3834C6.53889 20.6389 6.66667 20.9556 6.66667 21.3334C6.66667 21.7112 6.53889 22.0278 6.28333 22.2834C6.02778 22.5389 5.71111 22.6667 5.33333 22.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

ListIcon.displayName = 'ListIcon';

export default ListIcon;
