import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';
import { Plan, PlanNames } from '@/models/Plan';
import { ButtonLib, SubscriptionLabel } from '@/components';
import styles from './subscriptionPlanCard.module.css';

interface SubscriptionPlanCardProps {
  card: Plan;
  prevCardLabel?: Omit<PlanNames, 'Free'>;
  isHorizontal?: boolean;
  currentPlanId?: string;
}

export const SubscriptionPlanCard = ({
  card: {
    id,
    link_label,
    link_url,
    amount,
    name,
    trial_days,
    description,
    legal,
    highlights,
    isCustom
  },
  prevCardLabel,
  isHorizontal,
  currentPlanId
}: SubscriptionPlanCardProps) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const onSelectSubscription = () => {
    navigate(`${ROUTES.ACCOUNT_SUBSCRIPTION}/${name}`);
  };

  const enableHover = () => setHovered(true);
  const disableHover = () => setHovered(false);

  const isCurrentPlan = id === currentPlanId;
  const isRecommended = name === 'Plus';

  return (
    <div
      onMouseEnter={enableHover}
      onMouseLeave={disableHover}
      data-orientation={isHorizontal ? 'horizontal' : 'vertical'}
      className={`${styles.cardWrapper} ${isRecommended ? styles.recommended : ''}`}
    >
      {isRecommended ? <div className={`button ${styles.badge}`}>RECOMMENDED</div> : null}

      <div>
        <SubscriptionLabel label={name} />

        {isCustom ? '' : <div className={styles.price}>${amount}/mo.</div>}

        <div className={styles.descriptionWrapper}>
          <p className={styles.description}>{description}</p>

          {isHorizontal ? (
            <ButtonLib
              onClick={onSelectSubscription}
              background="primary"
              disabled={isCurrentPlan}
              size="md"
            >
              {isCurrentPlan ? 'CURRENT PLAN' : 'SELECT'}
            </ButtonLib>
          ) : null}
        </div>

        <div className={styles.didvider} />
        {!isCustom && prevCardLabel && (
          <p className={styles.descriptionSmall}>
            Everything in <span className={styles.prevCardLabel}>{prevCardLabel}</span> and...
          </p>
        )}
        <ul>
          {highlights.map((feature, index) => (
            <li className={styles.feature} key={index}>
              {feature}
            </li>
          ))}
        </ul>

        {isHorizontal && link_url && link_label ? (
          <a target="_blank" className={styles.textLink} href={link_url}>
            {link_label}
          </a>
        ) : null}
      </div>

      {!isHorizontal ? (
        <div className={styles.footer}>
          <ButtonLib
            fullWidth
            onClick={onSelectSubscription}
            background="secondary"
            variant="pill"
            className={styles.button}
          >
            {!hovered && trial_days ? `Try free for ${trial_days} days` : `Choose ${name} plan`}
          </ButtonLib>

          {legal ? <p className={styles.footerDescription}>{legal}</p> : null}

          {link_url && link_label ? (
            <a target="_blank" className={styles.footerlink} href={link_url}>
              {link_label}
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
