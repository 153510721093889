import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { SubscriptionBody, Subscription } from '@/models/Subscription';
import { notifications } from '@/utils/notifications';

export const usePutSubscription = () => {
  const api = useApi({});
  const queryClient = useQueryClient();

  return useMutation<Subscription, AxiosError, SubscriptionBody>({
    mutationFn: async (subscription): Promise<Subscription> => {
      const response = await api.put<Subscription>('/v1/subscription', subscription);

      return response.data;
    },
    onSuccess: async (subscription, params) => {
      void queryClient.setQueryData(['subscription'], subscription);

      if (params.card_token) {
        await queryClient.invalidateQueries({
          queryKey: ['account-card']
        });

        notifications.success('Subscription has been updated successfully');
      }
    },
    onError: (error, variables) => {
      const response = error.response;
      if (response && response.status === 422) {
        notifications.error(
          (response.data as SubscriptionBody).card_token ||
            'There has been an error processing your card. Please try again later'
        );
      } else {
        notifications.error(
          variables.plan_id
            ? 'Could not update your subscription. Please try again later'
            : 'There has been an error processing your card. Please try again later'
        );
      }
    }
  });
};
