import { SVGAttributes } from 'react';

const ReleasesIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.6665 19.9993C17.5998 19.9993 18.3887 19.6771 19.0332 19.0327C19.6776 18.3882 19.9998 17.5993 19.9998 16.666V9.33268H23.9998V6.66602H18.6665V13.9993C18.3776 13.7771 18.0665 13.6105 17.7332 13.4993C17.3998 13.3882 17.0443 13.3327 16.6665 13.3327C15.7332 13.3327 14.9443 13.6549 14.2998 14.2993C13.6554 14.9438 13.3332 15.7327 13.3332 16.666C13.3332 17.5993 13.6554 18.3882 14.2998 19.0327C14.9443 19.6771 15.7332 19.9993 16.6665 19.9993ZM10.6665 23.9993C9.93317 23.9993 9.30539 23.7382 8.78317 23.216C8.26095 22.6938 7.99984 22.066 7.99984 21.3327V5.33268C7.99984 4.59935 8.26095 3.97157 8.78317 3.44935C9.30539 2.92713 9.93317 2.66602 10.6665 2.66602H26.6665C27.3998 2.66602 28.0276 2.92713 28.5498 3.44935C29.0721 3.97157 29.3332 4.59935 29.3332 5.33268V21.3327C29.3332 22.066 29.0721 22.6938 28.5498 23.216C28.0276 23.7382 27.3998 23.9993 26.6665 23.9993H10.6665ZM5.33317 29.3327C4.59984 29.3327 3.97206 29.0716 3.44984 28.5493C2.92761 28.0271 2.6665 27.3993 2.6665 26.666V7.99935H5.33317V26.666H23.9998V29.3327H5.33317Z"
        fill="currentColor"
      />
    </svg>
  );
};

ReleasesIcon.displayName = 'ReleasesIcon';

export default ReleasesIcon;
