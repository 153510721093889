import { SVGAttributes } from 'react';

const SpotifyWhiteIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon-spotify" clipPath="url(#clip0_3575_20859)">
        <path
          id="Vector"
          d="M11.4995 0.0378418C5.16775 0.0378418 0.0346375 5.17081 0.0346375 11.5025C0.0346375 17.8345 5.16775 22.9671 11.4995 22.9671C17.8318 22.9671 22.9644 17.8345 22.9644 11.5025C22.9644 5.17122 17.8318 0.0383894 11.4993 0.0383894L11.4995 0.0378418ZM16.7571 16.5732C16.5518 16.91 16.111 17.0168 15.7742 16.81C13.0823 15.1658 9.69368 14.7934 5.70291 15.7052C5.31834 15.7928 4.93501 15.5519 4.84739 15.1672C4.75936 14.7825 4.99935 14.3992 5.38488 14.3115C9.75214 13.3138 13.4983 13.7434 16.5203 15.5902C16.8571 15.797 16.9639 16.2364 16.7571 16.5732ZM18.1604 13.4515C17.9017 13.8721 17.3513 14.0049 16.931 13.7461C13.8493 11.8519 9.15167 11.3033 5.50658 12.4098C5.03385 12.5526 4.53456 12.2862 4.39108 11.8143C4.2487 11.3415 4.51526 10.8432 4.98717 10.6994C9.15085 9.43608 14.3271 10.048 17.8661 12.2228C18.2864 12.4815 18.4192 13.0317 18.1604 13.4515ZM18.2809 10.2008C14.5858 8.00611 8.48947 7.80431 4.96157 8.87505C4.39505 9.04686 3.79596 8.72705 3.62428 8.16054C3.4526 7.59375 3.77214 6.99507 4.33906 6.82284C8.38884 5.59344 15.1211 5.83097 19.3753 8.35645C19.886 8.65887 20.053 9.31697 19.7504 9.82585C19.4492 10.3354 18.7894 10.5034 18.2814 10.2008H18.2809Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3575_20859">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SpotifyWhiteIcon.displayName = 'SpotifyWhiteIcon';

export default SpotifyWhiteIcon;
