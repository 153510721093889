import { SVGAttributes } from 'react';

const HomeIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33301 28V12L15.9997 4L26.6663 12V28H18.6663V18.6667H13.333V28H5.33301Z"
        fill="currentColor"
      />
    </svg>
  );
};

HomeIcon.displayName = 'HomeIcon';

export default HomeIcon;
