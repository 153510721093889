import { SVGAttributes } from 'react';

const FacebookIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 15.9918C32 7.16418 24.8321 0 16 0C7.16787 0 0 7.16418 0 15.9918C0 23.9794 5.84964 30.6001 13.5118 31.7859V20.6032H9.42533V15.9918H13.5118V12.4673C13.5118 8.46526 15.8847 6.24189 19.5427 6.24189C21.2894 6.24189 23.1019 6.57128 23.1019 6.57128V10.4745H21.0917C19.0978 10.4745 18.4717 11.7262 18.4717 12.9779V15.9753H22.9207L22.2122 20.5867H18.4717V31.7694C26.1504 30.6001 32 23.9794 32 15.9918Z"
        fill="#1877F2"
      />
      <path
        d="M22.2287 20.6197L22.9372 16.0082H18.4882V13.0108C18.4882 11.7427 19.1309 10.5075 21.1082 10.5075H23.1185V6.58775C23.1185 6.58775 21.3059 6.25836 19.5593 6.25836C15.9012 6.25836 13.5284 8.48173 13.5284 12.4838V16.0082H9.44189V20.6197H13.5284V31.8024C14.3358 31.9341 15.1762 32 16.0166 32C16.8569 32 17.6973 31.9341 18.5047 31.8024V20.6197H22.2287Z"
        fill="white"
      />
    </svg>
  );
};

FacebookIcon.displayName = 'FacebookIcon';

export default FacebookIcon;
